
import { getServerSideProps } from "src/lib/pages/Filmomtale";

import Filmomtale from 'src/lib/pages/Filmomtale';

//#region [Other]getServerSideProps
export { getServerSideProps };
//#endregion




//#region [Component]
export default Filmomtale;
//#endregion